import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bot Week continued with The Chatbot Conference on September 25th `}{`&`}{` 26th at San Francisco’s Fort Mason. Over 30 speakers from all over the world gathered to give talks on chatbot use cases and conversational building techniques.`}</p>
    <p>{`Here are the top 5 takeaways.`}</p>
    <h4>{`1. `}<strong parentName="h4">{`Chatbots in the education sector`}</strong></h4>
    <p>{`Chatbots are becoming increasingly popular and easier to deploy, taking as little as 2 weeks to have one up and running. The education sector, jumping on the bandwagon, is starting to use chatbots to educate and qualify incoming students. This allows students to be helped 24/7 while lowering the average cost per application.`}</p>
    <h4>{`2. `}<strong parentName="h4">{`Chatbots are currently at stage 3, but the goal is stage 5`}</strong></h4>
    <p>{`Stage three is already impressive with conditional automation and the ability to recall conversations. At stage 5, the chatbot can handle unseen problems and tasks in open domain, plan complex tasks, process abstract and complex info, and modify its knowledge and behavior.`}</p>
    <h4>{`3. `}<strong parentName="h4">{`Humans need empathy`}</strong></h4>
    <p>{`Although chatbots are a very powerful tool, in some cases people `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/24/customer-service-chatbots-providing-an-effective-solution/"
      }}>{`need empathy`}</a>{` that only a person could give. When finding out a product is counterfeit, which is a problem Adobe deals with a lot, people tend to go through the 7 stages of grief before they accept the news. When talking to a bot, these people tend to escalate and become more emotional very quickly. With a human agent who can empathize with their situation, they tend to understand and accept the situation better.`}</p>
    <h4>{`4. `}<strong parentName="h4">{`We are at the age of chatbots`}</strong></h4>
    <p>{`Every decade or so we are hit by a new wave of technological advancement. We went from the World Wide Web to iPhones and mobile technology. Now we’re experiencing a boon in artificial intelligence. It is estimated that by 2020, 70% of white collar workers will interact with AI daily. We are in the age of chatbots!`}</p>
    <h4>{`5. `}<strong parentName="h4">{`Conversation design is incredibly important to the bot experience`}</strong></h4>
    <p>{`When building a bot, there are the bot builders and the conversational designers. The conversational designers need to understand how people interact outside of the chatbot experiences before they can create a well-designed dialogue flow. Hans van Dam from `}<a parentName="p" {...{
        "href": "https://robocopy.io/"
      }}>{`Robocopy`}</a>{` suggests sitting back-to-back with another human to sample the dialogue before it is implemented.`}</p>
    <p>{`Although the conference was centered around all the new and exciting initiatives being pursued by startups and enterprises, there was some time dedicated to reminiscing about just how far the industry has come. For instance many of the jobs and tasks that a chatbot team performs every single day did not exist 3 and a half years ago. From copywriters to engineers, there seems to be a place for everyone to express their talents.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      